import { GameTitle, OverallScore, PlayerScore } from "./types";
import styles from "./component.module.scss";
import { v4 as uuidv4 } from "uuid";

const gameTitles: GameTitle[] = [
  "Technique",
  "Bop",
  "Numbers",
  "Order Up",
  "15 Green",
  "Terminal",
  "Laser Relay",
  "Bullet Train",
];

// Jogadores e seus scores para cada jogo (dados fictícios para exemplo)
const players: string[] = [
  "Gondim",
  "CrazyChris",
  "Azrael 38",
  "DrChamP",
  "JamesFromToronto",
  "Cowrider",
  "Corwyn",
  "T-bone_12",
];

const getHiddenName = (index: number) => `Anonymous #${index + 1}`;

const displayName = (player: PlayerScore, hiddenIndex: number) => {
  return player.hidden ? getHiddenName(hiddenIndex) : player.name;
};

const generateRandomScores = (): PlayerScore[] => {
  return players.map<PlayerScore>((player) => ({
    id: uuidv4(),
    name: player,
    scores: gameTitles.reduce((acc, game) => {
      acc[game] = Math.floor(Math.random() * 1000);
      return acc;
    }, {} as Record<GameTitle, number>),
    hidden: Math.random() < 0.3,
  }));
};

const playerData = generateRandomScores();

// Calcula a pontuação total de cada jogador
const overallScores = playerData.map<OverallScore>((player) => ({
  player,
  totalScore: Object.values(player.scores).reduce(
    (acc: any, score) => acc + score,
    0
  ),
}));

// Ordena o ranking geral pelo maior score
const sortedOverallScores = [...overallScores].sort(
  (a, b) => b.totalScore - a.totalScore
);

export const TournamentOfChampions = (): JSX.Element => {
  return (
    <div className={styles.container}>
      {/* Overall Ranking */}
      <div className={styles.overallRanking}>
        <h2>ToC 2026</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Player</th>
              <th>Total Score</th>
            </tr>
          </thead>
          <tbody>
            {sortedOverallScores.map(({ player, totalScore }, index) => {
              const hiddenIndex = sortedOverallScores
                .slice(0, index)
                .filter((e) => e.player.hidden).length;
              return (
                <tr key={index}>
                  <td>{displayName(player, hiddenIndex)}</td>
                  <td>{totalScore}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Game Tables */}
      <div className={styles.tablesContainer}>
        {gameTitles.map((game) => {
          const sortedPlayers = [...playerData].sort(
            (a, b) => b.scores[game] - a.scores[game]
          );

          return (
            <div key={game} className={styles.gameTable}>
              <h3>{game}</h3>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedPlayers.map((player, index) => {
                    const hiddenIndex = sortedPlayers
                      .slice(0, index)
                      .filter((p) => p.hidden).length;
                    return (
                      <tr key={index}>
                        <td>{displayName(player, hiddenIndex)}</td>
                        <td>{player.scores[game]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
};
