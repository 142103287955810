import { useState } from "react";

import {
  GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY,
  generateGameKey,
  INITIAL_MINI_TOURNAMENT_ACTIONS,
  MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY,
} from "./constants";
import styles from "./MiniTournament.module.scss";
import { TextInput } from "../../components";
import { getLocalStorageObject, setLocalStorageObject } from "../../utils";

type OnValueChange = {
  key: string;
  oldValue: string;
  newValue: string;
};

export const MiniTournament = (): JSX.Element | null => {
  const [games, setGames] = useState(
    getLocalStorageObject(GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY)
  );
  const [tournamentActions, setTournamentActions] = useState(
    getLocalStorageObject(MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY)
  );

  if (!games || !tournamentActions) {
    return null;
  }

  const onGameNameChange = ({
    key,
    newValue,
    oldValue,
  }: OnValueChange): void => {
    const gameScore = oldValue.split(";;")[1];
    setGames({ ...games, [key]: `${newValue};;${gameScore}` });
  };

  const onScoreChange = ({ key, newValue, oldValue }: OnValueChange): void => {
    const gameKey = oldValue.split(";;")[0];
    setGames((games) => {
      const _games = { ...games, [key]: `${gameKey};;${newValue}` };
      setLocalStorageObject(GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY, _games);
      return _games;
    });
  };

  const onBlurSave = (): void => {
    setLocalStorageObject(GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY, games);
  };

  const onRemoveButtonClick = (key: string) => {
    const filteredGames: Record<string, string> = {};
    for (let _key in games) {
      if (key !== _key) {
        filteredGames[_key] = games[_key];
      }
    }

    setGames(filteredGames);
    setLocalStorageObject(
      GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY,
      filteredGames
    );
  };

  const onAddGameClick = (): void => {
    const _games = { ...games, [generateGameKey()]: ";;" };
    setGames(_games);
    setLocalStorageObject(GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY, _games);
  };

  const onClearAllScoresClick = (): void => {
    setGames((games) => {
      const _games: Record<string, string> = {};
      for (const i in games) {
        const [gameKey] = games[i].split(";;");
        _games[i] = `${gameKey};;`;
      }

      setLocalStorageObject(GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY, _games);

      return _games;
    });
  };

  const onStartTournamentClick = (): void => {
    const _tournamentActions = {
      ...tournamentActions,
      startedPlaying: "true",
    };
    setTournamentActions(_tournamentActions);
    setLocalStorageObject(
      MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY,
      _tournamentActions
    );
  };

  const onFinishTournamentClick = (): void => {
    const _tournamentActions = {
      ...tournamentActions,
      finished: "true",
    };
    setTournamentActions(_tournamentActions);
    setLocalStorageObject(
      MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY,
      _tournamentActions
    );
  };

  const onRestartClick = (): void => {
    setTournamentActions(INITIAL_MINI_TOURNAMENT_ACTIONS);
    setLocalStorageObject(
      MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY,
      INITIAL_MINI_TOURNAMENT_ACTIONS
    );
  };

  const canEdit = tournamentActions["startedPlaying"] === "";

  const canRemove = Object.entries(games).length > 1 && canEdit;

  const canClearScore = (gameScore: string) => canEdit && gameScore;

  const isTournamentFinished = tournamentActions["finished"] !== "";

  const totalScore = Object.entries(games).reduce((acc, [, gameScore]) => {
    const [, score] = gameScore.split(";;");
    return acc + Number(score);
  }, 0);

  return (
    <div className={styles.wrapper}>
      <h1>Mini Tournament</h1>
      <div>
        {Object.entries(games).map(([key, value]) => {
          const [gameName, gameScore] = (value || "").split(";;");
          return (
            <div key={key} className={styles.gameWrapper}>
              <div>
                <strong className={styles.label}>Game</strong>
                <TextInput
                  placeholder="Enter game"
                  className={styles.keyInput}
                  value={gameName}
                  onChange={(e) =>
                    onGameNameChange({
                      key,
                      oldValue: value,
                      newValue: e.target.value,
                    })
                  }
                  onBlur={onBlurSave}
                  disabled={!canEdit}
                />
              </div>
              <div className={styles.scoreWrapper}>
                <strong className={styles.label}>Score</strong>
                <TextInput
                  value={gameScore}
                  placeholder="Enter score"
                  onChange={(e) =>
                    onScoreChange({
                      key,
                      oldValue: value,
                      newValue: e.target.value,
                    })
                  }
                  onBlur={onBlurSave}
                  disabled={isTournamentFinished || canEdit}
                  type="number"
                />
              </div>
              <div
                className={
                  (canClearScore(gameScore) && styles.buttonsWrapper) ||
                  styles.removeButton
                }
              >
                {canClearScore(gameScore) && (
                  <button
                    onClick={() => {
                      onScoreChange({ key, newValue: "", oldValue: value });
                    }}
                  >
                    Clear Score
                  </button>
                )}
                {canRemove && (
                  <button onClick={() => onRemoveButtonClick(key)}>
                    Remove Game
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {canEdit ? (
        <>
          <button onClick={onAddGameClick} className={styles.button}>
            Add Game
          </button>
          <button className={styles.button} onClick={onClearAllScoresClick}>
            Clear All Scores
          </button>
          <button onClick={onStartTournamentClick} className={styles.button}>
            Start Tournament
          </button>
        </>
      ) : (
        <>
          {isTournamentFinished ? (
            <>
              <strong className={styles.label}>Total Score</strong>
              <TextInput
                className={styles.keyInput}
                value={totalScore}
                disabled
              />
              <button onClick={onRestartClick} className={styles.button}>
                Restart
              </button>
            </>
          ) : (
            <button className={styles.button} onClick={onFinishTournamentClick}>
              Finish Tournament
            </button>
          )}
        </>
      )}
    </div>
  );
};
