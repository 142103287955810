import produce from "immer";
import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";

type State = {
  lastUpdatedPlayers?: string;
  lastUpdatedLeaderboards?: string;
  isApiDown: boolean;
};

const initialState: State = { isApiDown: false };

export const lastUpdatedReducer = produce(
  (state: State, action: Action): State => {
    switch (action.type) {
      case ActionType.FETCH_LAST_UPDATED:
        state.lastUpdatedPlayers = action.payload.players;
        state.lastUpdatedLeaderboards = action.payload.leaderboards;
        state.isApiDown = false;

        return state;
      case ActionType.API_DOWN:
        state.isApiDown = true;
        return state;
      default:
        return state;
    }
  },
  initialState
);
