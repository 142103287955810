import { useCallback, useEffect, useState } from "react";
import { MenuActive, RoomNameAndId } from "./components/SideMenu/SideMenu";
import { useActions, useTypedSelector } from "../../hooks";
import { UseActivateHookResponse } from "./types";

export const useActivateHook = (): UseActivateHookResponse => {
  const {
    playerInfo: { player, locations },
  } = useTypedSelector(({ playerInfo, lastUpdated }) => ({
    playerInfo,
    lastUpdated,
  }));
  const { clearPreviousSearch, clearPlayerYesterday, clearPlayerAllLocations } =
    useActions();

  const [menuActive, setMenuActive] = useState<MenuActive>("Account Summary");
  const [room, setRoom] = useState<RoomNameAndId>({ roomName: "Hoops" });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onRoomClick = useCallback((room: RoomNameAndId) => {
    setRoom(room);
  }, []);

  useEffect(() => {
    if (locations.length && !player.player_name) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [locations, player]);

  const handleBackButtonClick = useCallback(() => {
    if (menuActive !== "Account Summary") {
      setMenuActive("Account Summary");
    } else {
      clearPlayerAllLocations();
      clearPlayerYesterday();
      clearPreviousSearch();
    }
  }, [
    menuActive,
    clearPreviousSearch,
    clearPlayerAllLocations,
    clearPlayerYesterday,
  ]);

  return {
    menuActive,
    onRoomClick,
    room,
    handleBackButtonClick,
    isModalOpen,
    setIsModalOpen,
    setMenuActive,
  };
};
