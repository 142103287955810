import styles from "./SearchPlayer.module.scss";

import { useActions, useTypedSelector } from "../../../../hooks";
import { SearchInput } from "../../../../components/SearchInput/SearchInput";

export const SearchPlayer = (): JSX.Element => {
  const {
    playerInfo: { errorMessage },
    lastUpdated: { isApiDown },
  } = useTypedSelector(({ playerInfo, lastUpdated }) => ({
    playerInfo,
    lastUpdated,
  }));
  const { fetchPlayerInformation, clearPlayerAllLocations } = useActions();

  const handleSearchClick = (text: string) => {
    fetchPlayerInformation(text);
    clearPlayerAllLocations();
  };

  return (
    <div className={styles.wrapper}>
      <SearchInput
        additionalStyles={styles.searchInput}
        onSearchClick={handleSearchClick}
        placeholder="Enter profile name or bound key"
        error={errorMessage}
        focusOnRender
        disabled={isApiDown}
      />
    </div>
  );
};
