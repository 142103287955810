export enum ActionType {
  ADD_ALBUM = "add_album",
  FETCH_ALBUMS = "fetch_albums",
  FETCH_ALBUMS_COMPLETE = "fetch_albums_complete",
  // Strategy Game
  LOADING_FETCH_STRATEGY_GAME = "loading_fetch_strategy_game",
  CLEAR_STRATEGY_GAME = "clear_strategy_game",
  FETCH_STRATEGY_GAME_COMPLETE = "fetch_strategy_game_complete",
  FETCH_STRATEGY_GAME_ERROR = "fetch_strategy_game_error",
  // Player and Leaderboard
  LOADING_FETCH_PLAYER_INFO = "loading_fetch_player_info",
  CLEAR_PLAYER_INFO = "clear_player_info",
  FETCH_PLAYER_INFO_COMPLETE = "fetch_player_info_complete",
  FETCH_PLAYER_INFO_ERROR = "fetch_player_info_error",
  IS_LOADING_COMPARE_ACTION = "is_loading_compare_action",
  FETCH_PLAYER_YESTERDAY_INFO_COMPLETE = "fetch_player_yesterday_info_complete",
  CLEAR_PLAYER_YESTERDAY_INFO = "clear_player_yesterday_info",
  FETCH_PLAYER_YESTERDAY_INFO_ERROR = "fetch_player_yesterday_info_error",
  FETCH_LIST_PLAYERS = "fetch_list_players",
  ADD_NEW_COMPARED_PLAYERS = "add_new_compared_players",
  FETCH_PLAYER_ALL_LOCATIONS = "fetch_player_all_locations",
  CLEAR_PLAYER_ALL_LOCATIONS = "clear_player_all_locations",
  LOADING_FETCH_LEADERBOARD = "loading_fetch_leaderboard",
  FETCH_LEADERBOARD_COMPLETE = "fetch_leaderboard_complete",
  FETCH_LEADERBOARD_ERROR = "fetch_leaderboard_error",
  REMOVE_PLAYER_LIST_PLAYERS = "remove_player_list_players",
  FETCH_LOCATIONS_COMPLETE = "fetch_locations_complete",
  CLEAR_LOCATIONS = "clear_locations",
  SAVE_PLAYER_BEING_SEARCHED = "save_player_being_searched",
  // Filter Toggles
  TOGGLE_SHOW_ONLY_DIFFERENT_LEVELS = "toggle_show_only_different_levels",
  TOGGLE_SHOW_ONLY_LEVELS_TO_IMPROVE = "toggle_show_only_levels_to_improve",
  TOGGLE_SHOW_ONLY_LEVELS_TO_MATCH_SCORE = "toggle_show_only_levels_to_match_score",
  TOGGLE_SHOW_ONLY_MISSING_LEVELS = "toggle_show_only_missing_levels",
  TOGGLE_SHOW_ONLY_NOT_TOP_SCORES = "toggle_show_only_not_top_scores",
  TOGGLE_SHOW_ONLY_TOP_SCORES = "toggle_show_only_top_scores",
  TOGGLE_MULTI_GAME_VIEW = "toggle_multi_game_view",
  TOGGLE_SHOW_SCORE_DIFFERENCE_GAME_LEVEL = "toggle_show_score_difference_game_level",
  TOGGLE_SHOW_TOTAL_SCORE_PER_GAME = "toggle_show_total_score_per_game",
  // Last Updated
  FETCH_LAST_UPDATED = "fetch_last_updated",
  API_DOWN = "api_down",
  // Badges
  FETCH_BADGES_LOADING = "fetch_badges_loading",
  FETCH_BADGES_COMPLETE = "fetch_badges_complete",
  SORT_BADGES_BY_TITLES = "sort_badges_by_titles",
  SORT_BADGES_BY_STARS = "sort_badges_by_stars",
}
