import { useMemo } from "react";
import classNames from "classnames";

import styles from "./GameCard.module.scss";

type GameCardProps = {
  title: string;
  onGameClick: (name: string) => void;
  selectedGame: string;
};

export const GameCard = ({
  title,
  onGameClick,
  selectedGame,
}: GameCardProps): JSX.Element => {
  const buttonClassNames = useMemo(
    () =>
      classNames(styles.wrapper, selectedGame === title && styles.selectedGame),
    [selectedGame, title]
  );
  return (
    <button onClick={() => onGameClick(title)} className={buttonClassNames}>
      {title}
    </button>
  );
};
