import { useEffect } from "react";
// import ReactGA from "react-ga";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import styles from "./App.module.scss";

import { Activate } from "./pages";
import "./App.css";
import { getLocalStorageObject, setLocalStorageObject } from "./utils";
import {
  BINDED_PLAYERS_LOCAL_STORAGE_KEY,
  INITIAL_BINDED_PLAYERS,
} from "./pages/Activate/constants";
import {
  GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY,
  INITIAL_GAMES_MINI_TOURNAMENT,
  INITIAL_MINI_TOURNAMENT_ACTIONS,
  MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY,
} from "./pages/MiniTournament/constants";
import { useActions } from "./hooks";
import { TournamentOfChampions } from "./pages/TournamentOfChampions/component";
// import { LaserMaze } from "./pages/LaserMaze/LaserMaze";
// import { Strategy } from "./pages/Strategy/Strategy";
// import { Header } from "./components/Header/Header";
// import { Footer } from "./components/Footer/Footer";

// const TRACKING_ID = "UA-172018484-1";
// ReactGA.initialize(TRACKING_ID);

export const App = () => {
  const { fetchLastUpdated } = useActions();
  // initialize Bind Users Local Storage
  useEffect(() => {
    const bindUsers = getLocalStorageObject(BINDED_PLAYERS_LOCAL_STORAGE_KEY);
    const gamesMiniTournament = getLocalStorageObject(
      GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY
    );
    const miniTournamentActions = getLocalStorageObject(
      MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY
    );
    if (!bindUsers) {
      setLocalStorageObject(
        BINDED_PLAYERS_LOCAL_STORAGE_KEY,
        INITIAL_BINDED_PLAYERS
      );
    }

    if (!gamesMiniTournament) {
      setLocalStorageObject(
        GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY,
        INITIAL_GAMES_MINI_TOURNAMENT
      );
    }

    if (!miniTournamentActions) {
      setLocalStorageObject(
        MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY,
        INITIAL_MINI_TOURNAMENT_ACTIONS
      );
    }
  }, []);

  useEffect(() => {
    fetchLastUpdated();
  }, [fetchLastUpdated]);

  return (
    <div className={styles.wrapper}>
      {/* <Header /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Activate />} />
          <Route path="/toc" element={<TournamentOfChampions />} />
          {/* <Route path="/mini-tournament" element={<MiniTournament />} /> */}
          {/* <Route path="/maze" element={<LaserMaze />} /> */}
          {/* <Route path="/strategy/:level" element={<Strategy />} /> */}
          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
    </div>
  );
};
